import { graphql, Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import Banner from "$components/Banner"
import Container from "$components/Container"
import Footer from "$components/Footer"
import Header from "$components/Header"
import PostPreview from "$components/PostPreview"
import SEO from "$components/SEO"

import bannerInsights from "./assets/banner-insights.jpg"

const ArticlesLink = styled(Link)`
  color: ${props => props.theme.tundora};
  display: block;
  font-size: 1.2rem;
  margin: 2rem auto;
  text-align: right;
`

const BannerContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const CentredP = styled.p`
  text-align: center;
`

const H3 = styled.h3`
  font-weight: 400;
  margin: 1rem auto;
  text-align: left;
`

const RightP = styled.p`
  margin: 1rem auto;
  text-align: right;
`

const Ul = styled.ul`
  font-size: 1.2rem;
  margin: 0 auto;
`

const title = "Insights | Lotus Network Solutions"
const description =
  "Keep up to date with technology with articles and resources covering current IT topics."

export default ({ data }) => (
  <>
    <SEO description={description} title={title} />
    <Header />
    <main>
      <Banner bannerImage={bannerInsights} height={400}>
        <BannerContainer>
          <h1>Insights</h1>
        </BannerContainer>
      </Banner>
      <section>
        <CentredP>
          Find relevant, up-to-date information for your business here.
        </CentredP>
      </section>
      <section>
        <Container>
          <h2>Articles</h2>
          <PostPreview nodes={data.wpcontent.posts.nodes} />
          <ArticlesLink to="/articles/">See all articles &#187;</ArticlesLink>
        </Container>
      </section>
      <section>
        <Container>
          <h2>Resources</h2>
          <CentredP>Get the latest updates from trusted sources.</CentredP>
          <H3>Australian Taxation Office (ATO)</H3>
          <Ul>
            <li>
              <a
                href="https://www.ato.gov.au/General/JobKeeper-Payment/Employers/Eligible-employers/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Eligible employers
              </a>
              : employer eligibility for JobKeeper payments
            </li>
            <li>
              <a
                href="https://www.ato.gov.au/General/COVID-19/Top-calls-about-COVID-19/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Top calls about COVID-19
              </a>
              : frequently asked questions about JobKeeper, early release of
              super
            </li>
          </Ul>
          <RightP>
            <a
              href="https://www.ato.gov.au/Whats-new.aspx"
              rel="noopener noreferrer"
            >
              Read more at ATO
            </a>
          </RightP>
          <H3>CPA Australia</H3>
          <Ul>
            <li>
              <a
                href="https://www.cpaaustralia.com.au/-/media/corporate/allfiles/document/media/media-release/early-access-to-super-covid-19-measure.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                Early access to super COVID-19 measure
              </a>
            </li>
            <li>
              <a
                href="https://www.cpaaustralia.com.au/-/media/corporate/allfiles/document/media/media-release/media-release-jobkeeper-legislation-passes-parliament.pdf"
                rel="noopener noreferrer"
                target="_blank"
              >
                JobKeeper will deliver a lifeline to workers and businesses,
                says CPAs
              </a>
            </li>
          </Ul>
          <RightP>
            <a
              href="https://www.cpaaustralia.com.au/media/media-releases"
              rel="noopener noreferrer"
            >
              Read more at CPA Australia
            </a>
          </RightP>
        </Container>
      </section>
    </main>
    <Footer />
  </>
)

export const query = graphql`
  query {
    wpcontent {
      posts(first: 3) {
        nodes {
          author {
            name
          }
          date
          excerpt
          featuredImage {
            altText
            mediaItemUrl
          }
          slug
          title
        }
      }
    }
  }
`
